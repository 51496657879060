import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import ModalWithHeader from '../ModalWithHeader';
import { StyledInfo } from '../Icons/Info';
import Portal from '../Portal';
import { LinkText } from './styles';

class InfoLink extends React.Component {
  state = {
    showModal: false,
  };

  openModal = (e) => {
    this.setState({ showModal: true });
    this.props.onClick(e);
  };

  closeModal = (e) => {
    this.setState({ showModal: false });
    this.props.onClose(e);
  };

  render() {
    const {
      text,
      linkText,
      title,
      children,
      ...rest
    } = this.props;

    const txt = text || linkText;

    return (
      <React.Fragment>
        {txt && 
        (
          <LinkText role="button" {...rest} onClick={this.openModal}>
            {
              // text in icon ensures height is consistent with adjacent text
              txt
            }
          </LinkText>
        )
        }
        {!txt && 
        (
          <StyledInfo role="button" {...rest} onClick={this.openModal}>
            {
              // text in icon ensures height is consistent with adjacent text
              'Learn More'
            }
          </StyledInfo>
        )
        }
        {
          this.state.showModal &&
          <Portal>
            <ModalWithHeader header={title} onClose={this.closeModal}>
              {children}
            </ModalWithHeader>
          </Portal>
        }
      </React.Fragment>
    );
  }
}

InfoLink.propTypes = {
  text: PropTypes.node,
  /** (DEPRECATED) Alias of `text` prop. Please use that instead. */
  linkText: PropTypes.node,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
};

InfoLink.defaultProps = {
  text: '',
  linkText: '',
  title: '',
  onClick: noop,
  onClose: noop,
};

export default InfoLink;
