import styled, { css } from 'styled-components';
import { toPixels } from '../../utils/styling';

export const ContentWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;

  ${props => props.withPadding && css`
    padding: 30px;
  `}
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 0 40px rgba(0, 0, 0, .5);

  ${props => props.size === 'sm' && css`
    /* Example: FBT Add to Cart modal */
    width: 30%;
  `}

  ${props => props.size === 'md' && css`
    /* Example: Example: Check Nearby Stores; Definitions; most others */
    width: 50%;
    max-width: 1000px;
  `}

  ${props => props.size === 'lg' && css`
    /* Example: None */
    /* Just eyeballing it */
    width: 60%;
    min-width: 1000px;
  `}

  ${props => props.size === 'xl' && css`
    /* Example: Visualizer */
    width: 90%;
    height: 90%;
  `}

  ${props => props.width && css`
    width: ${toPixels(props.width)};
  `}

  ${props => props.height && css`
    height: ${toPixels(props.height)};
  `}

  @media screen and (max-width: 1000px) {
    max-width: 100%;
  }

  @media screen and (max-width: 768px) {
    height: 100vh;
    width: 100vw;
  }
`;

export const OverlayWrapper = styled.div.attrs({
  role: 'presentation',
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000;
`;
