import styled from 'styled-components';

export const StyledInfo = styled.span`
  background: url(https://assets.thdstatic.com/images/v1/info.svg) no-repeat;
  display: inline-block;
  text-indent: -999em;	
  font-size: 0;	
  position: relative;
  top: 0;
  width: 20px;
  height: 20px;
  transform: scale(0.8) translate(20%);
  float: none;
  cursor: pointer;
  pointer-events: auto;
  user-select: auto;
`;