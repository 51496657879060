import React, { memo } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import MarkdownToJsx from 'markdown-to-jsx';
import styled from 'styled-components';
import { List, ListItem, ListLink } from '../List';

const StyledMarkdown = styled(MarkdownToJsx)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }
`;

const options = onListLinkAnalyticsTrack => ({
  overrides: {
    ul: List,
    li: ListItem,
    a: { component: ListLink, props: { onListLinkAnalyticsTrack } },
  },
});

/**
 * Styled markdown-to-jsx wrapper.
 *
 * Supports react-markdown's `source` prop for compatibility.
 */
const Markdown = memo(({
  source, children, onListLinkAnalyticsTrack, ...rest
}) => (
  <StyledMarkdown {...rest} options={options(onListLinkAnalyticsTrack)}>
    {source || children}
  </StyledMarkdown>
));

Markdown.propTypes = {
  children: PropTypes.node,
  /** (DEPRECATED) for backwards compatibility with old react-markdown implementation */
  source: PropTypes.string,
  onListLinkAnalyticsTrack: PropTypes.func,
};

Markdown.defaultProps = {
  children: '',
  source: '',
  onListLinkAnalyticsTrack: noop,
};

export default Markdown;
