/* eslint-disable max-len */
import React from 'react';
import withSizePresets from './withSizePresets';
import { Svg } from './styles';

const X = props => (
  <Svg {...props} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32">
    <g>
      <path d="M27.229 8.391l-3.385-3.386-7.843 7.838-7.84-7.84-3.386 3.385 7.609 7.608-7.613 7.612 3.385 3.386 7.843-7.838 7.84 7.841 3.387-3.386-7.61-7.608z" />
    </g>
  </Svg>
);

export default withSizePresets(20, 20)(X);