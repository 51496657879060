import styled, { css } from 'styled-components';
import { borderColor, thdOrange } from '../../utils/colors';

// export const G = styled.g``;

export const Svg = styled.svg.attrs(() => ({
  xmlns: 'http://www.w3.org/2000/svg',
  version: '1.1',
}))`
  g {
    fill: ${thdOrange};
  }
  ${props => props.color && css`
    g {
      fill: ${props.color};
    }
  `}
  ${props => props.disabled && css`
    opacity: 0.54;
    g {
      fill: ${borderColor};
    }
  `}
`;

export const SBRow = styled.div`
  display: block;
  box-sizing: border-box;
  padding: 10px;
  margin: 10px;
`;

export const SBTitle = styled.h2`
  display: block;
  font-size: 16px;
  font-weight: bolder;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
`;