import styled, { css } from 'styled-components';
import X from '../Icons/X';

export const HeadingCol = styled.div`
  flex-grow: 1;
`;

export const CloseCol = styled.div``;

export const CloseIcon = styled(X).attrs({ role: 'button' })`
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;

  & > div {
    padding: 10px;
  }

  ${props => props.bordered && css`
    border-bottom: 2px solid #eee;
  `}

  ${props => props.centered && css`
    ${HeadingCol},
    ${CloseCol} {
      display: flex;
      align-items: center;
    }
  `}
`;
